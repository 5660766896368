import { call, put } from "redux-saga/effects";
import API from "../../network/apis/viewAllRequestsAPI";
import * as ACTION from "../actions/viewAllRequests";
import { dispatchError, showSnackbar, getURLParams } from "../../utils/shared";
import store from "../../store";
import History from "./../../routes/History";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/viewAllRequests";
import FileSaver from "file-saver";

export function* requestRequestsInProgress({ payload }) {
  try {
    const response = yield call(API.requestRequestsInProgress, payload);
    yield put(ACTION.receiveRequestsInProgress(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* deleteRequestInprogress({ payload }) {
  try {
    yield call(API.deleteRequestInprogress, payload.requestId);
    yield put(ACTION.firstViewALLRequestJobOpeningRequest());
    yield put(ACTION.requestRequestsInProgress(payload.pageNumber));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* publishedJobs({ payload }) {
  try {
    const publishedJobsRes = yield call(API.requestPublishedJobs, payload);
    yield put(ACTION.publishedJobsRecieve(publishedJobsRes.data));
  } catch (error) { }
}

export function* requestRequestsClosed({ payload }) {
  try {
    const response = yield call(API.requestRequestsClosed, payload);
    yield put(ACTION.receiveRequestsClosed(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestAllJobOpenings({ payload }) {
  try {
    const response = yield call(API.requestAllJobOpenings, payload);
    yield put(ACTION.receiveAllJobOpenings(response.data));
  } catch (error) { }
}

export function* delayClosingDate({ payload }) {
  try {
    yield call(API.delayClosingDate, {
      id: payload.id,
      closing_date: payload.closing_date,
    });
    showSnackbar("done");
    payload.type === "reopen"
      ? yield put(ACTION.reOpenHoldedJobsRequest(payload.id))
      : yield put(
        ACTION.requestAllJobOpenings(
          History.location.search.split("=")[1] || 1
        )
      );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestApprovalsListFlow({ payload }) {
  try {
    const response = yield call(API.requestApprovalStatusFlow, payload);
    yield put(ACTION.receiveApprovalStatusFLow(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* requestCompanyInterviewMinutes() {
  try {
    const response = yield call(API.requestCompanyInterviewMinutes);
    yield put(ACTION.receiveCompanyInterviewMinutes(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestCheckJobsLimit() {
  try {
    const response = yield call(API.checkJobsLimit);
    yield put(ACTION.receiveCheckJobsLimit(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* closeJobOpening({ payload: { id, src } }) {
  try {
    yield call(API.closeJobOpening, id);
    showSnackbar("done");
    src === "publishedJobs"
      ? yield put(ACTION.publishedJobsRequset(getURLParams("page")))
      : src === "holdedJobs"
        ? yield put(ACTION.getHoldedJobsRequest(getURLParams("page")))
        : yield put(ACTION.requestAllJobOpenings(getURLParams("page")));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestGetHoldedJobsList({ payload }) {
  try {
    const response = yield call(API.getHoldingJobsList, payload);
    yield put(ACTION.getHoldedJobsReceive(response.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* holdPublishedJobs({ payload: { id, src } }) {
  try {
    yield call(API.holdJobOpening, id);
    showSnackbar("done");
    src === "publishedJobs"
      ? yield put(ACTION.publishedJobsRequset(getURLParams("page")))
      : yield put(ACTION.requestAllJobOpenings(getURLParams("page")));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* reOpenHoldedJobs({ payload }) {
  try {
    yield call(API.reOpenJobOpening, payload);
    getURLParams("state") === "holded"
      ? yield put(ACTION.getHoldedJobsRequest(getURLParams("page")))
      : yield put(ACTION.requestRequestsClosed(getURLParams("page")));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* duplicateJobPost({ payload }) {
  try {
    const response = yield call(API.duplicateJobPost, payload);
    History.push(
      `/manpowerRequests/${response.data.data}?type=published&hasCandidate=false`
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* firstViewALLRequestJobOpeningRequest() {
  try {
    const response = yield call(API.firstViewALLRequestJobOpeningRequest);
    yield put(ACTION.firstViewALLRequestJobOpeningRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}


export function* exportExcelAllRequest() {
  try {
    const response = yield call(API.exportExcelAllRequest);
    yield put(ACTION.exportExcelAllRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Manpower request-[All].xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsAllRequest() {
  try {
    const response = yield call(API.exportCvsAllRequest);
    yield put(ACTION.exportCvsAllRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Manpower request-[All].csv");

  } catch (error) {
    console.log(error);
  }
}


export function* exportExcelPendingRequest() {
  try {
    const response = yield call(API.exportExcelPendingRequest);
    yield put(ACTION.exportExcelPendingRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Manpower request-[Pending].xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsPendingRequest() {
  try {
    const response = yield call(API.exportCvsAllRequest);
    yield put(ACTION.exportCvsAllRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Manpower request-[Pending].csv");

  } catch (error) {
    console.log(error);
  }
}



export function* exportExcelClosedRequest() {
  try {
    const response = yield call(API.exportExcelClosedRequest);
    yield put(ACTION.exportExcelClosedRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Manpower request-[Closed].xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsClosedRequest() {
  try {
    const response = yield call(API.exportCvsClosedRequest);
    yield put(ACTION.exportCvsClosedRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Manpower request-[Closed].csv");

  } catch (error) {
    console.log(error);
  }
}





export function* exportExcelHoldRequest() {
  try {
    const response = yield call(API.exportExcelHoldRequest);
    yield put(ACTION.exportExcelHoldRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Manpower request-[Hold].xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsHoldRequest() {
  try {
    const response = yield call(API.exportCvsHoldRequest);
    yield put(ACTION.exportCvsHoldRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Manpower request-[Hold].csv");

  } catch (error) {
    console.log(error);
  }
}


export function* exportExcelPublishedRequest() {
  try {
    const response = yield call(API.exportExcelPublishedRequest);
    yield put(ACTION.exportExcelPublishedRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, 'Manpower request-[Published].xlsx');
  } catch (error) {
    console.log(error);
  }
}

export function* exportCvsPublishedRequest() {
  try {
    const response = yield call(API.exportCvsPublishedRequest);
    yield put(ACTION.exportCvsPublishedRecieve(response.data));
    console.log(response.data)
    var blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Manpower request-[Published].csv");

  } catch (error) {
    console.log(error);
  }
}

export function* ViewAllRequestsSagasWatch() {
  yield takeLatest(
    type.REQUEST_REQUESTS_IN_PROGRESS,
    requestRequestsInProgress
  );
  yield takeLatest(type.REQUESTS_CLOSED_REQUEST, requestRequestsClosed);
  yield takeLatest(type.PUBLISHED_JOBS_REQUEST, publishedJobs);
  yield takeLatest(type.REQUEST_ALL_JOB_OPENINGS, requestAllJobOpenings);
  yield takeLatest(type.DELAY_CLOSING_DATE_REQUSET, delayClosingDate);
  yield takeLatest(type.DELETE_REQUEST_IN_PROGRESS, deleteRequestInprogress);
  yield takeLatest(
    type.APPROVAL_SETUP_STATUS_FLOW_REQUEST,
    requestApprovalsListFlow
  );
  yield takeLatest(
    type.COMPANY_INTERVIEW_MINUTES_REQUEST,
    requestCompanyInterviewMinutes
  );
  yield takeLatest(
    type.CHECK_JOB_REQUESTS_PLAN_LIMIT_REQUEST,
    requestCheckJobsLimit
  );
  yield takeLatest(type.CLOSE_JOB_OPENING_REQUEST, closeJobOpening);
  yield takeLatest(type.GET_HOLDED_JOBS_REQUEST, requestGetHoldedJobsList);
  yield takeLatest(type.HOLD_PUBLISHED_JOBS_REQUEST, holdPublishedJobs);
  yield takeLatest(type.REOPEN_HOLDED_JOBS_REQUEST, reOpenHoldedJobs);
  yield takeLatest(type.DUPLICATE_JOB_POST_REQUEST, duplicateJobPost);
  yield takeLatest(
    type.FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_REQUEST,
    firstViewALLRequestJobOpeningRequest
  );
  yield takeLatest(type.EXPORT_CVS_ALL_REQUEST, exportCvsAllRequest);
  yield takeLatest(type.EXPORT_EXCEL_ALL_REQUEST, exportExcelAllRequest);

  yield takeLatest(type.EXPORT_CVS_PENDING_REQUEST, exportCvsPendingRequest);
  yield takeLatest(type.EXPORT_EXCEL_PENDING_REQUEST, exportExcelPendingRequest);

  yield takeLatest(type.EXPORT_CVS_CLOSED_REQUEST, exportCvsClosedRequest);
  yield takeLatest(type.EXPORT_EXCEL_CLOSED_REQUEST, exportExcelClosedRequest);

  yield takeLatest(type.EXPORT_CVS_HOLD_REQUEST, exportCvsHoldRequest);
  yield takeLatest(type.EXPORT_EXCEL_HOLD_REQUEST, exportExcelHoldRequest);

  yield takeLatest(type.EXPORT_CVS_PUBLISHED_REQUEST, exportCvsPublishedRequest);
  yield takeLatest(type.EXPORT_EXCEL_PUBLISHED_REQUEST, exportExcelPublishedRequest);
}
