import { call, put } from "redux-saga/effects";
import API from "../../network/apis/candidatePortalSetup";
import { candidatePortalSetupRecieve } from "../actions/candidatePortalSetup";
import { showSnackbar, dispatchError } from "utils/shared";
import {
  candidatePortalSetupPage_Colors,
  LinkWebsiteWithPlan,
  PostingThroughSocialMediaWithPlan,
  companyBriefWithPlan,
} from "utils/CompanyPortalSetup";
import * as type from "../types/candidatePortalSetup";
import { takeLatest } from "redux-saga/effects";
import History from "routes/History";
import { companyPortalSetupRequest } from "store/actions";

export function* candidatePortalSetup({ payload }) {
  try {
    const response = yield call(API.candidatePortalSetup, payload);
    const disableColors = candidatePortalSetupPage_Colors(
      response.data.data
    );
    const disableLinkWebsite = LinkWebsiteWithPlan(response.data.data);
    const hideCompanyBrief = companyBriefWithPlan(response.data.data);
    const hidePostThroughMedia = PostingThroughSocialMediaWithPlan(
      response.data.data
    );
    yield put(
      candidatePortalSetupRecieve({
        ...response.data.data,
        disableLinkWebsite: disableLinkWebsite.disabled,
        hidePostThroughMedia: hidePostThroughMedia.hide,
        hideCompanyBrief: hideCompanyBrief.hide,
        ...disableColors,
      })
    );
  } catch (error) {
    dispatchError(error?.response?.data)
  }
}

export function* saveCandidateProfileSetup({ payload }) {
  try {
    yield call(API.saveCandidatePortalSetup, payload);
    yield put(companyPortalSetupRequest());
    showSnackbar("savedSuccessfuly");
    History.goBack();
  } catch (error) {
    dispatchError(error?.response?.data)
  }
}

export function* setCandidateDefaultColor() {
  try {
    yield call(API.setCandidateDefaultColor);
    yield call(candidatePortalSetup, {})
    yield put(companyPortalSetupRequest());
    showSnackbar("savedSuccessfuly");
  } catch (error) {
    dispatchError(error?.response?.data)
  }
}
export function* setCandidateDefaultLogo() {
  try {
    const response = yield call(API.setCandidateDefaultLogo);
    yield put(
      candidatePortalSetupRecieve({ candidate_home_image: response.data.data })
    );
    showSnackbar("savedSuccessfuly");
  } catch (error) {
    dispatchError(error?.response?.data)
  }
}

export function* CandidatePortalSetupSagasWatch() {
  yield takeLatest(type.CANDIDATE_PORTAL_SETUP_REQUEST, candidatePortalSetup);
  yield takeLatest(type.SAVE_PROFILE_SETUP_REQUEST, saveCandidateProfileSetup);
  yield takeLatest(
    type.SET_CANDIDATE_DEFAULT_LOGO_REQUEST,
    setCandidateDefaultLogo
  );
  yield takeLatest(
    type.SET_CANDIDATE_DEFAULT_COLOR_REQUEST,
    setCandidateDefaultColor
  );
}
