import * as types from '../types';

const INITIAL_STATE = {
    closeBtn : 'block',
    openBtn : 'none',
    companyLogoSideMenuHeight : '100px',
    companyLogoSideMenuWidth : '100px',
    sideMenuWidth : '250px',
    sideMenuContent : 'block',
    sideMenuContentPaddingTop : '8px',
    sideMenuContentPaddingBottom : '8px',
    mainMrginLeft : '290px',
    mainMarginRight : '40px',
    navbarMarginLeft : '250px',
    navbarMarginRight : '0px',
    curvedNavbLeft : '16em',
    curvedNavbRight : 'auto',
    showPopover : 'none'
} ;

const sideMenu = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.SET_SIDE_MENU :
            return {...state , ...action.payload};
        default:
            return state;
    }
}

export default sideMenu;