import { call, put } from "redux-saga/effects";
import API from "../../network/apis/wizardSetup";
import { setWizardStep } from "../actions/wizardSetup";
import * as ACTION from "../actions/wizardSetup";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/wizardSetup";

export function* wizardSetupRequest({ payload }) {
  try {
    yield call(API.wizardSetupRequest, payload);
    localStorage.setItem("activeDashboardStepperIndex", 2);
    localStorage.setItem("is_wizard", true);
    localStorage.setItem("is_company_profile_complete", true);
    showSnackbar("savedSuccessfuly");
    localStorage.setItem('company_image_url' , payload.company_details.logo)
    localStorage.setItem('company_name' , payload.company_details.company_name_en)
    showSnackbar("savedSuccessfuly");
    yield put(setWizardStep(2));
    yield put(ACTION.setupWizardIndex(1))
  } catch (error) {
    dispatchError(error.response?.data);
    yield put(ACTION.setupWizardIndex(1))
  }
}

export function* requestPhoneNumberValidation({payload}) {
  try {
    const response = yield call(API.requestPhoneNumberValidation, payload);
    yield put(ACTION.phoneValidationRecieve(response));
    yield put(ACTION.isPhoneValid(true));
    yield put(ACTION.setupWizardIndex(4))
  } catch (error) {
    dispatchError(error.response?.data);
    yield put(ACTION.isPhoneValid(false));
    yield put(ACTION.setupWizardIndex(4))
  }
}
export function* WizardSetupSagasWatch() {
  yield takeLatest(type.WIZARD_SETUP_REQUEST, wizardSetupRequest);
  yield takeLatest(type.PHONE_VALIDATION_REQUEST, requestPhoneNumberValidation);
}
