import * as types from "../types/emailTemplates";

export default (
  state = {
    isTemplateDeleted: false,
    paging: {
      total_record: 0,
    },
    template: {
      body: "",
      template_name_ar: "",
      template_name_en: "",
      subject: "",
    },
    customTemplate: {
      subject: "",
      body: {
        has_text: "dd",
        value: ""
      },
      errors: false,
    },
    data: [],
    placeholders: [],
    customTemplateList: [],
  },
  action
) => {
  switch (action.type) {
    case types.CUSTOMIZE_EMAIL_TEMPLATE_RECIEVE:
      return {
        ...state,
        data: action.payload.data,
        paging: action.payload.paging,
        template: {
          body: "",
          template_name_ar: "",
          template_name_en: "",
          subject: "",
        },
      };
    case types.EDIT_EMAIL_TEMPLATE_RECIEVE:
      return { ...state, template: action.payload };
    case types.GET_EMAIL_TEMPLATE_PLACEHOLDERS_RECIEVE:
      return { ...state, placeholders: action.payload };
    case types.GET_EMAIL_TEMPLATE_LIST_RECIEVE:
      return {
        ...state,
        customTemplateList: action.payload.map((item) => ({
          ...item,
          type: "custom-template",
        })),
      };
    case types.GET_CANDIDATE_EMAIL_TEMPLATE_RECIEVE:
      return {
        ...state,
        customTemplate: {
          ...action.payload,
          body: {
            has_text: "has",
            value: action.payload.body,
          },
        },
      };
    case types.UPDATE_CUSTOM_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        customTemplate: action.payload,
      };
      case types.EMAIL_TEMPLATE_DELETED:
      return {
        ...state,
        isTemplateDeleted: action.payload,
      };
    default:
      return state;
  }
};
