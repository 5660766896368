import * as types from "../types/applicantProfile";

export const requestApplicantProfile = (payload) => ({
  type: types.GET_APPLICANT_PROFILE_REQUEST,
  payload,
});

export const receiveApplicantProfile = (payload) => ({
  type: types.GET_APPLICANT_PROFILE_RECEIVE,
  payload,
});

export const requestCandidateScreening = (payload) => {
  return {
    type: types.GET_APPLICATION_REQUEST,
    payload,
  };
};

export const receiveCandidateScreening = (payload) => {
  return {
    type: types.GET_APPLICATION_RECEIVE,
    payload,
  };
};
export const requestApplicantMainData = (payload) => {
  return {
    type: types.GET_APPLICANT_MAIN_DATA_REQUEST,
    payload,
  };
};

export const receiveApplicantMainData = (payload) => ({
  type: types.GET_APPLICANT_MAIN_DATA_RECEIVE,
  payload,
});

export const requestApplicatPreofferAttachements = (payload) => {
  return {
    type: types.GET_APPLICANT_PREOFFER_ATTACHEMENTS_REQUEST,
    payload,
  };
};

export const requestCandidateApplications = (payload) => {
  return {
    type: types.GET_CANDIDATE_APPLICATIONS_REQUEST,
    payload,
  };
};

export const receiveCandidateApplications = (payload) => {
  return {
    type: types.GET_CANDIDATE_APPLICATIONS_RECEIVE,
    payload,
  };
};

export const receiveApplicatPreofferAttachements = (payload) => {
  return {
    type: types.GET_APPLICANT_PREOFFER_ATTACHEMENTS_RECEIVE,
    payload,
  };
};

export const requestApplicantInterviews = (payload) => ({
  type: types.GET_APPLICANT_INTERVIEWS_REQUEST,
  payload,
});
export const receiveApplicantInterviews = (payload) => ({
  type: types.GET_APPLICANT_INTERVIEWS_RECEIVE,
  payload,
});
export const requestCandidateLog = (payload) => ({
  type: types.GET_CANDIDATE_LOG_REQUEST,
  payload,
});
export const receiveCandidateLog = (payload) => ({
  type: types.GET_CANDIDATE_LOG_RECEIVE,
  payload,
});

export const requestAllCandidateLogs = (payload) => ({
  type: types.GET_ALL_CANDIDATE_LOGS_REQUEST,
  payload,
});
export const receiveAllCandidateLogs = (payload) => ({
  type: types.GET_ALL_CANDIDATE_LOGS_RECEIVE,
  payload,
});

export const requestDisableAdvert = () => ({
  type: types.DISABLE_ADVERT_REQUEST,
});
export const receiveDisableAdvert = (payload) => ({
  type: types.DISABLE_ADVERT_RECEIVE,
  payload,
});

export const requestGetMoreInfoAnswers = (payload) => ({
  type: types.GET_MORE_INFO_ANSWERS_REQUEST,
  payload,
});
export const receiveGetMoreInfoAnswers = (payload) => ({
  type: types.GET_MORE_INFO_ANSWERS_RECEIVE,
  payload,
});

export const requestCandidateOffers = (payload) => ({
  type: types.GET_APPLICANT_OFFERS_REQUEST,
  payload,
});
export const receiveCandidateOffers = (payload) => ({
  type: types.GET_APPLICANT_OFFERS_RECEIVE,
  payload,
});
export const getContractBytesRequest = (payload) => ({
  type: types.GET_CONTRACT_FILE_BYTES,
  payload,
})
export const getContractBytesRecieve = (payload) => ({
  type: types.GET_CONTRACT_FILE_BYTES_RECIEVE,
  payload,
})
export const requestCandidateContracts = (payload) => ({
  type: types.GET_APPLICANT_CONTRACTS_REQUEST,
  payload,
});
export const receiveCandidateContracts = (payload) => ({
  type: types.GET_APPLICANT_CONTRACTS_RECEIVE,
  payload,
});
export const disableSendMoreInfoRequest = () => {
  return {
    type: types.DISABLE_SEND_MORE_INFO_REQUEST,
  }
};
export const disableSendEmailRequest = () => {
  return {
    type: types.DISABLE_SEND_EMAIL_REQUEST,
  }
};

export const disableSendMoreInfoRecieve = (payload) => {
  return {
    type: types.DISABLE_SEND_MORE_INFO_RECIEVE,
    payload
  }
};
export const disableSendEmailRecieve = (payload) => {
  return {
    type: types.DISABLE_SEND_EMAIL_RECIEVE,
    payload
  }
};

