import { axiosInstance } from "./config";

let handlerEnabled = true;

const candidateTalentPool = async (params) => {
  return await axiosInstance.get(`/admin/talent_Pool/getlist`, {
    params,
  });
};
const candidateTalentPoolJobOpening = async (data) => {
  return await axiosInstance.post(
    `/admin/candidates/add_multi_candidates_to_job`,
    data,
    { handlerEnabled }
  );
};
const getPipelineApplications = async ({
  params,
  job_request_id,
}) =>{
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications`,
    {
      params: {
        ...params,
        page_size: 10,
      },
      handlerEnabled,
    }
  );
}
const UploadApplicantsExcel = async (data) => {
  return await axiosInstance.post(`/admin/talent_Pool/Upload_ApplicantExcel`, data,  {
    handlerEnabled,
  });
};
const addApplicants = async (data) =>
  await axiosInstance.post(`/admin/talent_Pool/addapplicant`, data, {
    handlerEnabled,
  });
  const getPipelineStages = async (job_request_id) =>
  await axiosInstance.get(`/admin/job_requests/${job_request_id}/pipeline`, {
    handlerEnabled,
  });
export default {
  candidateTalentPool,
  candidateTalentPoolJobOpening,
  getPipelineStages,
  UploadApplicantsExcel,
  addApplicants,
  getPipelineApplications
};
