export const dynamicTextEditorUpdates = (content, oldValue, newValue) => {
  return content.replace(oldValue, newValue);
};

export const replaceText = (content, valuesToBeReplaced) => {
  const re = /\[time]|\[date]|\[expiration-date]|\[interviewer-name]/gi;
  return content.replace(re, function (matched) {
    const key = matched.slice(1, -1);
    return `<strong>${valuesToBeReplaced[key]}</strong>`;
  });
};
