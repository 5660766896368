import { call, put } from "redux-saga/effects";
import API from "../../network/apis/jobTitles";
import * as jobTitlesActions from "../actions/jobTitles";
import history from "../../routes/History";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/jobTitles";
export function* listJobTitles({ payload }) {
  try {
    const response = yield call(API.listJobTitles, payload);
    yield put(jobTitlesActions.listJobTitles(response.data));
  } catch (error) {
    console.log(error.response);
  }
}
export function* addEditJobTitle({ payload }) {
  try {
    const response = yield call(API.addEditJobTitle, payload.data);
    yield put(jobTitlesActions.addEditJobTitleReceive(response.data));
    showSnackbar("savedSuccessfuly");
    payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* JobTitleSagasWatch() {
  yield takeLatest(type.LIST_JOB_TITLES_REQUEST, listJobTitles);
  yield takeLatest(type.ADD_EDIT_JOB_TITLE_REQUEST, addEditJobTitle);
}
