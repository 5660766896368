import { axiosInstance } from "./config";

let handlerEnabled = true;

const getPipelineApplications = async ({ params, job_request_id }) => {
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications`,
    {
      params: {
        ...params,
        page_size: 10,
      },
      handlerEnabled,
    }
  );
};

const getPipelineStages = async (job_request_id) =>
  await axiosInstance.get(`/admin/job_requests/${job_request_id}/pipeline`, {
    handlerEnabled,
  });
const scheduleInterview = async (data) =>
  await axiosInstance.post(
    `/admin/job_requests/${data.job_request_id}/applications/${data.data.candidate_application_id}/interviews`,
    data.data,
    { handlerEnabled }
  );

const requestGetScheduleData = async ({
  interview_id,
  application_id,
  job_request_id,
}) =>
  await axiosInstance.get(
    `admin/job_requests/${job_request_id}/applications/${application_id}/interviews/${interview_id}`,
    { handlerEnabled }
  );

const reScheduleInterview = async ({
  params: { job_request_id, application_id, interview_id },
  data,
}) =>
  await axiosInstance.put(
    `admin/job_requests/${job_request_id}/applications/${application_id}/interviews/${interview_id}`,
    data,
    { handlerEnabled }
  );

const addApplicants = async (data) =>
  await axiosInstance.post(`/admin/candidates`, data, {
    handlerEnabled,
  });

const rejectApplicants = async ({ job_request_id, data }) => {
  return await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${data.candidate_application_id}/actions/reject`,
    data
  );
};

const moveCandidatesToStages = async ({ job_request_id, data }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${data.candidate_application_id}/new_stage`,
    data
  );

const requestPreofferData = async (candidate_id) =>
  await axiosInstance.get(
    `/admin/candidates/${candidate_id}/preoffer/attachment_types`
  );

const sendApplicantPreoffer = async ({
  job_request_id,
  application_id,
  data,
}) =>
  await axiosInstance.post(
    `admin/job_requests/${job_request_id}/applications/${application_id}/actions/preoffer
  `,
    data
  );

const addRateToApplicants = async (data) =>
  await axiosInstance.post(
    `/admin/candidates/${data.candidate_id}/AddEditRate`,
    data
  );

const getApplicatRate = async (candidate_id) =>
  await axiosInstance.get(`/admin/candidates/GetRate/${candidate_id}`, {
    handlerEnabled,
  });

const sendCustomizedEmail = async (data) =>
  await axiosInstance.post(
    `/admin/candidates/send_customized_email`,
    data
  );

const sendUpdateCVEmail = async (data) => {
  return await axiosInstance.post(
    `/admin/candidates/send_update_resume_email`,
    data
  );
};

const addToAnotherJobRequest = async (data) =>
  await axiosInstance.post(
    `admin/candidates/add_candidate_to_job`,
    data
  );

const disableAddApplicants = async () =>
  await axiosInstance.get(`/admin/candidates/disable_add_applicant`);
const disableEngagement = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_engagement`);

const AddMoreApplicantInfo = async ({ data }) =>
  await axiosInstance.post(`/admin/moreInformationRequest/add`, data);

const SendEmailChangeStage = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_email`,
    data
  );

const getSendOfferTemplate = async ({ application_id, job_request_id }) =>
  await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_offer`
  );
const sendContract = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_contract`,
    data
  );
const sendReContract = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_reContract`,
    data
  );
export const downloadContractPDFFileRequest = async ({ id }) => {
  return await axiosInstance.get(
    `/admin/contracts/downloadContract_AttachFile/${id}`,
    {
      responseType: "arraybuffer",
      disableLoader: true,
    }
  );
};

const getSendContractTemplate = async ({ application_id, job_request_id }) =>
  await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_contract`
  );
const sendOffer = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_offer`,
    data
  );

const sendReoffer = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_reoffer`,
    data
  );

const getOfferApprovalsSetup = async () =>
  await axiosInstance.get(`admin/offers/approval_setup`);
const getContractApprovalsSetup = async () =>
  await axiosInstance.get(`admin/contracts/approval_setup`);

const getOfferApprovalStatusList = async (offer_id) =>
  await axiosInstance.get(`admin/offers/${offer_id}/approval_status`);

const requestFavoriteCandidate = async (candidate_id) =>
  await axiosInstance.post(`/admin/candidates/toggle_favourite`, {
    candidate_id,
  });

const requestDemo = async (payload) =>
  axiosInstance.post(`/company/request_demo`, payload);

const getContractApprovalStatusList = async (contract_id) =>
  await axiosInstance.get(`admin/contracts/${contract_id}/approval_status`);

export default {
  scheduleInterview,
  getPipelineApplications,
  requestGetScheduleData,
  reScheduleInterview,
  addApplicants,
  getPipelineStages,
  rejectApplicants,
  moveCandidatesToStages,
  requestPreofferData,
  sendApplicantPreoffer,
  addRateToApplicants,
  getApplicatRate,
  sendCustomizedEmail,
  sendUpdateCVEmail,
  addToAnotherJobRequest,
  disableAddApplicants,
  disableEngagement,
  AddMoreApplicantInfo,
  SendEmailChangeStage,
  getSendOfferTemplate,
  getSendContractTemplate,
  sendContract,
  sendOffer,
  sendReoffer,
  getOfferApprovalsSetup,
  getContractApprovalsSetup,
  getOfferApprovalStatusList,
  requestFavoriteCandidate,
  requestDemo,
  sendReContract,
  getContractApprovalStatusList,
};
