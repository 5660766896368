import * as types from "../types/CompanySetup";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_COMPANY_DETAILS_RECEIVE:
      return { ...state, ...action.payload };
    case types.EDIT_COMPANY_DETAILS_RECEIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
