import { call, put } from "redux-saga/effects";
import API from "../../network/apis/applicantProfile";
import * as ACTION from "../actions/applicantProfile";
import * as type from "../types/applicantProfile";
import { dispatchError } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import { requestPipelineStages } from "../actions/manageCandidates";

export function* getApplicantProfile({ payload }) {
  try {
    const response = yield call(API.getApplicantProfile, payload);
    yield put(ACTION.receiveApplicantProfile(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestAllCandidateLogs({ payload }) {
  try {
    const response = yield call(API.requestAllCandidateLogs, payload);
    yield put(ACTION.receiveAllCandidateLogs(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestCandidateScreening({ payload }) {
  try {
    const response = yield call(API.requestCandidateScreening, payload);
    yield put(ACTION.receiveCandidateScreening(response.data.data));
  } catch (err) {
    // dispatchError(err.response?.data);
  }
}
export function* requestApplicantMainData({ payload }) {
  try {
    const response = yield call(API.requestApplicantMainData, payload);
    yield put(ACTION.receiveApplicantMainData(response.data.data));
    yield put(requestPipelineStages(payload.job_request_id));
  } catch (err) {
    // dispatchError(err.response?.data);
  }
}
export function* requestApplicantInterviews({ payload }) {
  try {
    const response = yield call(API.requestApplicantInterviews, payload);
    yield put(ACTION.receiveApplicantInterviews(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestCandidateApplications({ payload }) {
  try {
    const response = yield call(API.requestCandidateApplications, payload);
    yield put(ACTION.receiveCandidateApplications(response.data));
  } catch (err) {
    // dispatchError(err.response?.data);
  }
}
export function* requestPreOfferAttachements({ payload }) {
  try {
    const response = yield call(API.requestPreofferAttachements, payload);
    yield put(ACTION.receiveApplicatPreofferAttachements(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* requestCandidateLog({ payload }) {
  try {
    const response = yield call(API.requestCandidateLog, payload);
    yield put(ACTION.receiveCandidateLog(response.data.data));
  } catch (err) {
    // dispatchError(err.response?.data);
  }
}
export function* requestDisableAdvert() {
  try {
    const response = yield call(API.disableAdvert);
    yield put(ACTION.receiveDisableAdvert(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestGetMoreInfoAnswers({ payload }) {
  try {
    const response = yield call(API.getMoreInfoAnswers, payload);
    yield put(ACTION.receiveGetMoreInfoAnswers(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* requestCandidateOffers({ payload }) {
  try {
    const response = yield call(API.getCandidateOffers, payload);
    yield put(ACTION.receiveCandidateOffers(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* contractBytesRequest({payload}) {
  try {
    yield put(ACTION.getContractBytesRecieve(null));
    const response = yield call(API.get_contractFileBytes, payload);
    yield put(ACTION.getContractBytesRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestCandidateContracts({ payload }) {
  try {
    const response = yield call(API.getCandidateContracts, payload);
    yield put(ACTION.receiveCandidateContracts(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* disableSendMoreInfoRequest() {
  try {
    const response = yield call(API.disableSendMoreInfoRequest);
    yield put(ACTION.disableSendMoreInfoRecieve(response.data?.data));
  } catch (err) {
    console.log(err);
  }
}
export function* disableSendEmailRequest() {
  try {
    const response = yield call(API.disableSendEmailRequest);
    yield put(ACTION.disableSendEmailRecieve(response.data?.data));
  } catch (err) {
    console.log(err);
  }
}

export function* ApplicantProfileSagasWatch() {
  yield takeLatest(type.GET_APPLICANT_PROFILE_REQUEST, getApplicantProfile);

  yield takeLatest(type.GET_APPLICATION_REQUEST, requestCandidateScreening);
  yield takeLatest(
    type.GET_APPLICANT_MAIN_DATA_REQUEST,
    requestApplicantMainData
  );
  yield takeLatest(
    type.GET_CANDIDATE_APPLICATIONS_REQUEST,
    requestCandidateApplications
  );
  yield takeLatest(
    type.GET_APPLICANT_INTERVIEWS_REQUEST,
    requestApplicantInterviews
  );
  yield takeLatest(
    type.GET_APPLICANT_PREOFFER_ATTACHEMENTS_REQUEST,
    requestPreOfferAttachements
  );
  yield takeLatest(type.GET_CANDIDATE_LOG_REQUEST, requestCandidateLog);
  yield takeLatest(
    type.GET_ALL_CANDIDATE_LOGS_REQUEST,
    requestAllCandidateLogs
  );
  yield takeLatest(type.DISABLE_ADVERT_REQUEST, requestDisableAdvert);
  yield takeLatest(
    type.GET_MORE_INFO_ANSWERS_REQUEST,
    requestGetMoreInfoAnswers
  );
  yield takeLatest(type.GET_APPLICANT_OFFERS_REQUEST, requestCandidateOffers);
  yield takeLatest(type.GET_APPLICANT_CONTRACTS_REQUEST, requestCandidateContracts);
  yield takeLatest(type.DISABLE_SEND_EMAIL_REQUEST, disableSendEmailRequest);
  yield takeLatest(type.DISABLE_SEND_MORE_INFO_REQUEST, disableSendMoreInfoRequest);
  yield takeLatest(type.GET_CONTRACT_FILE_BYTES, contractBytesRequest);
}
