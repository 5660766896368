import { axiosInstance } from "./config";

let handlerEnabled = true;

const login = async (data) => {
  return await axiosInstance.post("/admin/auth/login", data, {
    handlerEnabled,
  });
};

const checkDomain = async (domain) => {
  return await axiosInstance.get(`/admin/auth/company/${domain}`, {
    handlerEnabled,
  });
};

const forgetPassword = async (data) => {
  return await axiosInstance.post(`/admin/auth/forget_password`, data, {
    handlerEnabled,
  });
};

const resetPassword = async (data) => {
  return await axiosInstance.post(`/admin/auth/reset_password`, data, {
    handlerEnabled,
  });
};

const getCompanyDetails = async () => {
  return await axiosInstance.get(`/Company/get`, { handlerEnabled });
};

const editCompany = async (data) => {
  return await axiosInstance.put(`/Company/update`, data, { handlerEnabled });
};

const getBranches = async () => {
  return await axiosInstance.get(`/branch/get`, { handlerEnabled });
};

const addUpdateBranches = async (data) => {
  return await axiosInstance.put(`/branch/addEdit`, data, { handlerEnabled });
};

const deleteBranch = async (data) => {
  return await axiosInstance.delete(`/branch/delete`, data, { handlerEnabled });
};

const getJobRole = async (id) => {
  return await axiosInstance.get(`/admin/job_role/${id}`, { handlerEnabled });
};

const addJobRole = async (data) => {
  return await axiosInstance.post(`/admin/job_role/add`, data, {
    handlerEnabled,
  });
};

const editJobRole = async (data) => {
  return await axiosInstance.put(`/admin/job_role/update`, data, {
    handlerEnabled,
  });
};

const deleteJobRole = async (id) => {
  return await axiosInstance.delete(`/admin/job_role/delete/${id}`, {
    handlerEnabled,
  });
};

const getCountriesLookup = async () => {
  return await axiosInstance.get(`/lookup/country/get`, { handlerEnabled });
};
const getCitiesLookup = async (id) => {
  return await axiosInstance.get(`/lookup/city/get/${id}`, { handlerEnabled });
};

const getAllJobRolesLookup = async (id) => {
  return await axiosInstance.get(`/admin/job_role/lookup`, { handlerEnabled });
};

const getAllJobRoles = async (pageNumber) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
  };
  return await axiosInstance.get(`/admin/job_role/list`, {
    params,
    handlerEnabled,
  });
};

const getAllPermissions = async () => {
  return await axiosInstance.get(`/admin/job_role/pages`, { handlerEnabled });
};
const getApprovalSetup = async () => {
  return await axiosInstance.get(`/admin/approval_setup/get`, {
    handlerEnabled,
  });
};

const saveApprovalSetup = async (data) => {
  return await axiosInstance.post(`/admin/approval_setup/post`, data, {
    handlerEnabled,
  });
};
const jobRolesWithApprovals = async () => {
  return await axiosInstance.get(`/admin/job_role/approval_action`, {
    handlerEnabled,
  });
};
const socialLoginsRequest = async (data) => {
  return await axiosInstance.post("/Company/SocialSignUp", data);
};
const getCompanyPortalSetupRequest = async () =>
await axiosInstance.get(`/Company/company_data_by_domain_name`, {
  handlerEnabled,
});

export default {
  login,
  checkDomain,
  forgetPassword,
  resetPassword,
  getCompanyDetails,
  editCompany,
  getBranches,
  addUpdateBranches,
  deleteBranch,
  getJobRole,
  addJobRole,
  editJobRole,
  getCountriesLookup,
  getCitiesLookup,
  getAllJobRoles,
  getAllPermissions,
  getAllJobRolesLookup,
  deleteJobRole,
  getApprovalSetup,
  saveApprovalSetup,
  jobRolesWithApprovals,
  socialLoginsRequest,
  getCompanyPortalSetupRequest
};
