import React, { useEffect, useState } from "react";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Navbar, Nav } from "react-bootstrap";
import { setCurrentLang } from "../../store/actions/Lang";
import { requestGetAgentProfile } from "../../store/actions/agents";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "components/DrawerMenu/DrawerMenu";
import DropDownMenu from "components/UserDropdownMenu/UserDropdownMenu";
import "./Navbar.scss";
import history from "routes/History";
import { getCandidatePortalHome } from "utils/redirections";
import { Button } from "@material-ui/core";
import bell from "../../assets/images/ic_bell.svg";

function Navbars(props) {
  const {
    locale: { lang },
    wizardStep: { step: wizardStepper },
  } = useSelector((state) => state);
  const currentPage = useSelector((state) => state.activePageIndex.name);
  const sideMenu = useSelector((state) => state.sideMenu);
  const unseeenNotification = useSelector(
    (state) => state.notification.popupNotification?.unseen_number
  );
  const isWizard = JSON.parse(localStorage.getItem("is_wizard"));
  const [titlePage, setTitlePage] = useState("");
  const { messages } = props.intl;
  const dispatch = useDispatch();

  const switcher = (lang) => {
    dispatch(setCurrentLang(lang === "ar" ? "en" : "ar"));
  };

  useEffect(() => {
    setTitlePage(history.location.pathname.split("/")[1]);
    console.log(titlePage)
  });

  useEffect(() => {
    dispatch(requestGetAgentProfile());
  }, []);
  return (
    <React.Fragment>
      <div className="navbar-wrapper">
        <Navbar
          collapseOnSelect
          expand="lg"
          style={
            wizardStepper === 2 || isWizard
              ? {
                marginLeft: sideMenu.navbarMarginLeft,
                marginRight: sideMenu.navbarMarginRight,
              }
              : {
                marginLeft: 0,
                marginRight: 0,
              }
          }
          className="fixed-top"
        >
          {console.log(titlePage)}
          {(wizardStepper === 2 || isWizard) && <DrawerMenu />}
          {titlePage == "" && (
            <Navbar.Brand className="bold_font mb-0 mx-5">
              {messages.sideMenu.Dashboard}
            </Navbar.Brand>
          )}
          <Navbar.Brand className="bold_font mb-0 mx-5">
            {messages.sideMenu[titlePage]}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <Nav.Link className="align-self-center">
                <div className={unseeenNotification > 0 ? "unseen-icon" : ""}>
                  <ClickAwayListener onClickAway={props.handleClickAway}>
                    <img
                      src={bell}
                      alt="notification"
                      onClick={props.handleNotificationBellClick}
                    />
                  </ClickAwayListener>

                </div>
              </Nav.Link>
              <div className="align-self-center">
                <DropDownMenu />
              </div>
              <Nav.Link className="align-self-center">
                <Button className={`langBtn`} onClick={() => switcher(lang)}>
                  {messages.langBtn}
                </Button>
              </Nav.Link>
              <Nav.Link
                disabled={wizardStepper === 2 ? false : !isWizard}
                className=" jobPortal-link rounded_btn align-self-center"
              >
                <Button
                  variant="contained"
                  disabled={wizardStepper === 2 ? false : !isWizard}
                  className={`jobPortal`}
                  color="secondary"
                  onClick={() =>
                    window.open(getCandidatePortalHome(), "_blank").focus()
                  }
                >
                  {" "}
                  {messages.jobPortal}{" "}
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </React.Fragment>
  );
}

const HeaderComponent = injectIntl(Navbars);

export default HeaderComponent;
