import * as types from "../types/videoInterview";

const INITIAL_STATE = {
  startInterview: false,
  finishInterview: false,
  isInterviewReviewModalOpen: false,
  endSession: false,
  interviewTokens: {},
  disableFinishInterviewBtn: false,
  isSubscribed: false,
  chatMessages: [],
  resetEvaluation: false,
  evalutionTypes: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_INTERVIEW_RECEIVE:
      return {
        ...state,
        startInterview: action.payload.startInterview,
        interviewTokens: action.payload.data,
      };
    case types.FINISH_INTERVIEW_RECEIVE:
      return {
        ...state,
        finishInterview: action.payload,
      };
    case types.END_SESSION_RECIEVE:
      return {
        ...state,
        endSession: action.payload,
      };
    case types.DISABLE_FINISH_INTERVIEW_BTN:
      return {
        ...state,
        disableFinishInterviewBtn: action.payload
      }
    case types.HANDLE_SUBSCRIPTION:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    case types.TOGGLE_INTERVIEW_REVIEW_MODAL:
      return {
        ...state,
        isInterviewReviewModalOpen: action.payload
      };
    case types.INTERVIEW_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: [...state.chatMessages, ...action.payload],
      };
    case types.RESET_EVALUATION_FORM:
      return {
        ...state,
        resetEvaluation: action.payload,
      };
    case types.EVALUTION_TYPES_RECEIVE:
      return {
        ...state,
        evalutionTypes: action.payload.data,
      };
    default:
      return state;
  }
};
