import * as types from "../types/candidatePortalSetup";

export const candidatePortalSetupRequest = () => {
    return {
      type: types.CANDIDATE_PORTAL_SETUP_REQUEST,
    }
};

export const candidatePortalSetupRecieve = (data) => {
    return {
      type: types.CANDIDATE_PORTAL_SETUP_RECIEVE,
      payload : data
    }
};

export const saveProfileSetupRequest = (payload) => {
  return {
    type: types.SAVE_PROFILE_SETUP_REQUEST,
    payload 
  }
};
export const SubscriptionsHistoryPlanRecieve = (payload) => {
  return {
    type: types.SUBSCRIPTIONS_HISTORY_PLAN_RECIEVE,
    payload 
  }
}
export const setCandidateDefaultLogoRequest = () => {
  return {
    type: types.SET_CANDIDATE_DEFAULT_LOGO_REQUEST
  }
};
export const setCandidateDefaultLogoRecieve = (payload) => {
  return {
    type: types.SET_CANDIDATE_DEFAULT_LOGO_RECIEVE,
    payload 
  }
}
export const setCandidateDefaultColorRequest = () => {
  return {
    type: types.SET_CANDIDATE_DEFAULT_COLOR_REQUEST
  }
};
export const setCandidateDefaultColorRecieve = (payload) => {
  return {
    type: types.SET_CANDIDATE_DEFAULT_COLOR_RECIEVE,
    payload 
  }
}